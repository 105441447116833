import { DataGrid } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ICONS } from '../../../../../../constants/Icons';
import { useViewport } from '../../../../../../hooks';
import { getPendingNotifications } from '../../../../../../redux/Reducer/Profile';
import { toggleSnackBar } from '../../../../../../redux/Reducer/Utils';
import {
  getGBBAI,
  getValidationData,
  getValidationDetails,
  updateValidationDetails,
} from '../../../../../../services/DataValidation';
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LoadingButton,
  MaterialReactTable,
  MenuItem,
  Select,
  SnackBar,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '../../../../../Containers/index';
import Confirm from './Confirm';
import DataRetrive from './DataRetrive';
import Disclaimer from './Disclaimer';
const formatDate = (date) => {
  return moment(date).format('DD MMM YYYY');
};

function CustomNoRowsOverlay() {
  return (
    <div>
      <Box
        sx={{
          mt: 12,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        No results found.
      </Box>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    '& .MuiTableRow-root.MuiTableRow-head': {
      backgroundColor: '#17236E !important',
    },
    '& .MuiDataGrid-row--borderBottom ': {
      backgroundColor: '#17236E !important',
    },
    '& .MuiBox-root.caution img': {
      width: '100px',
      height: '100px',
    },
    '& .Mui-TableHeadCell-Content-Wrapper.MuiBox-root': {
      color: '#ffff',
      fontWeight: 400,
    },
    // '& .MuiButtonBase-root.MuiIconButton-root': {
    //   color: '#ffff',
    // },
    '& .MuiTablePagination-actions .Mui-disabled.MuiButtonBase-root.MuiIconButton-root':
      {
        color: '#808080',
      },
    '& .MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root': {
      color: 'black',
    },
    // '& .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon':
    //   {
    //     color: '#ffff',
    //   },
    '& a': {
      textDecoration: 'none',
      color: 'black',
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
  },
});
const sourceList = [
  {
    id: 'GIFE',
    status: 'GIFE',
  },
  {
    id: 'RICE',
    status: 'RICE',
  },
  {
    id: 'invoice',
    status: 'Invoice',
  },
  {
    id: 'purchase_order',
    status: 'Purchase Order',
  },
  {
    id: 'working_capital',
    status: 'Working Capital',
  },
  {
    id: 'letters_of_credit',
    status: 'Letters Of Credit',
  },
];
function DataValidation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [dataRetrive, setDataRetrive] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isHold, setIsHold] = useState(false);
  const [status, setStatus] = useState('');
  const [source, setSource] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const { isDBG, isChannel, isBNR, isBDF } = useSelector(
    (state) => state?.auth
  );

  const [rowCountState, setRowCountState] = useState(pageCount || 0);
  const [validationStatus, setValidationStatus] = useState(false);
  // const [{ pageIndex, pageSize }, setPagination] = useState({
  //   pageIndex: 0,
  //   pageSize: 50,
  // });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const options = [
    {
      id: 'all',
      status: 'All',
    },
    {
      id: 'validated',
      status: 'Validated',
    },
    {
      id: 'validation_failed',
      status: 'Validation Failed',
    },
    {
      id: 'partially_validated',
      status: 'Partially Validated',
    },
    {
      id: 'pending',
      status: 'Pending',
    },

    {
      id: 'withdrawn',
      status: 'Withdrawn  ',
    },
    {
      id: 're_submitted',
      status: 'Updated  ',
    },
    {
      id: 'on_hold',
      status: 'On Hold  ',
    },
  ];
  const [statusId, setStatusId] = useState('all');
  const [searchKeyword, setSearchKeyword] = useState('');

  const handleChange = (event) => {
    const id = event.target.value;
    setStatusId(id);
    getValidationRequests(id);
  };
  const handleToFChange = (event) => {
    const id = event.target.value;
    setSource(id);
    // getValidationRequests(statusId);
    // getValidationRequests(id);
  };
  const [isLoadingSumbit, setIsLoadingSumbit] = useState(false);
  const [isGBBLoading, setIsGBBLoading] = useState(false);
  const [isDraftSumbit, setIsDraftSumbit] = useState(false);
  const [loanAmount, setLoanAmount] = useState('');
  const [facilityType, setFacilityType] = useState('');
  const [loanTenure, setLoanTenure] = useState('');
  const [validationData, setValidationData] = useState([]);
  const [validationDetails, setValidationDetails] = useState(null);
  const [id, setId] = useState('');
  const [isClear, setIsClear] = useState(true);
  const [sortField, setSortField] = useState('');
  const [isGBBAI, setIsGBBAI] = useState('');
  const [showRefresh, setShowRefresh] = useState(false);

  const [showGBBAI, setShowIsGBBAI] = useState(false);
  const [sortOrder, setSortOrder] = useState('');
  function update(item) {
    var obj = _.find(validationDetails, ['field_name', item.field_name]);
    _.assign(obj, item);
  }
  const getRequestDetails = async (id) => {
    try {
      setIsLoading(true);
      setShowRefresh(false);
      const result = await getValidationDetails(id, 'data_validation');
      setShowIsGBBAI(false);
      if (result) {
        setLoanAmount(result?.data?.data?.amount);
        setFacilityType(result?.data?.data?.facility_type);
        setLoanTenure(result?.data?.data?.preferred_tenure);
        setValidationDetails(result?.data?.data?.data_validation_requests);
        // console.log(result?.data?.data?.status);
        setStatus(result?.data?.data?.status);
        setIsGBBAI(result?.data?.data?.gbb_stac_ai_info_last_updated_at);

        if (
          result?.data?.data?.gbb_stac_ai_info_last_updated_at !== null ||
          result?.data?.data?.status === 'pending' ||
          result?.data?.data?.status === 'partially_validated' ||
          result?.data?.data?.status === 'partially_validated' ||
          result?.data?.data?.status === 're_submitted' ||
          result?.data?.data?.status === 'on_hold'
        ) {
          setShowIsGBBAI(true);
        } else {
          setShowIsGBBAI(false);
        }
        if (
          result?.data?.data?.status === 'validated' ||
          result?.data?.data?.status === 'withdrawn' ||
          result?.data?.data?.status === 'validation_failed' ||
          result?.data?.data?.status === 'on_hold'
        ) {
          setValidationStatus(true);
        } else {
          setValidationStatus(false);
        }
      }
    } catch (error) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleGGBAI = async () => {
    try {
      setIsGBBLoading(true);
      setDataRetrive(true);
      const result = await getGBBAI({ request_id: id });
      // console.log(result);
      // if (result) {
      setShowRefresh(true);
      // dispatch(
      //   toggleSnackBar({
      //     isOpen: true,
      //     type: 'success',
      //     message: result?.data?.message,
      //   })
      // );
      setIsGBBLoading(false);
      getRequestDetails(id);
      getValidationRequests(statusId);
      // }
    } catch (error) {
      setIsGBBLoading(false);
      // dispatch(
      //   toggleSnackBar({
      //     isOpen: true,
      //     type: 'error',
      //     message: error,
      //   })
      // );
    } finally {
    }
  };
  const requestsColumns = [
    {
      flex: 0.1,
      minWidth: 170,
      sortable: true,
      field: 'source',
      headerName: 'Type of Financing',
    },
    {
      // accessorKey: 'company_name',
      // header: 'Company Name',
      flex: 0.1,
      minWidth: 170,
      sortable: true,
      field: 'company_name',
      headerName: 'Company Name',
    },

    {
      flex: 0.1,
      minWidth: 170,
      sortable: true,
      field: 'date_of_request',
      headerName: 'Date of Request',
      renderCell: ({ row }) =>
        `${new Date(row.date_of_request).toLocaleDateString('en-GB')}`,
      // accessorKey: 'date_of_request',
      // accessorFn: (row) =>
      //   `${new Date(row.date_of_request).toLocaleDateString('en-GB')}`,

      // header: 'Date of Request',
    },
    {
      flex: 0.1,
      minWidth: 170,
      sortable: true,
      field: 'request_id',
      headerName: 'Request ID ',
      // accessorKey: 'request_id',
      // header: 'Request ID ',
    },

    {
      flex: 0.1,
      minWidth: 100,
      // maxWidth: 200,
      sortable: true,
      field: '',
      headerName: 'Status',
      //accessorKey: 'status',
      // enableSorting: false,
      // header: 'Status ',
      renderCell: ({ row }) => {
        if (row.status === 'pending') {
          return (
            <Tooltip
              title='New application pending review'
              arrow
              placement='right'
            >
              <Chip
                color='warning'
                sx={{ color: '#fff', minWidth: 17, width: 170, maxWidth: 170 }}
                // sx={{ background: '#808080', color: '#fff' }}
                label={
                  row.status?.charAt(0).toUpperCase() + row.status?.slice(1)
                }
              />
            </Tooltip>
          );
        } else if (row.status === 'on_hold') {
          return (
            <Tooltip
              title='Application sent back to SME for revised/additional information'
              arrow
              placement='right'
            >
              <Chip
                sx={{ minWidth: 17, width: 170, maxWidth: 170 }}
                color='error'
                label={'On Hold'}
              />
            </Tooltip>
          );
        } else if (row.status === 're_submitted') {
          return (
            <Tooltip
              title='Application Information has been updated by the SME'
              arrow
              placement='right'
            >
              <Chip
                sx={{ minWidth: 17, width: 170, maxWidth: 170 }}
                color='warning'
                label={'Updated'}
              />
            </Tooltip>
          );
        } else if (row.status === 'partially_validated') {
          return (
            <Tooltip
              title='Data Validation is saved as a draft'
              arrow
              placement='right'
            >
              <Chip
                sx={{ minWidth: 17, width: 170, maxWidth: 170 }}
                color='warning'
                label={'Partially Validated'}
              />
            </Tooltip>
          );
        } else if (row.status === 'withdrawn') {
          return (
            <Tooltip
              title='Application has been withdrawn'
              arrow
              placement='right'
            >
              <Chip
                sx={{ minWidth: 17, width: 170, maxWidth: 170 }}
                color='error'
                label={'Withdrawn'}
              />
            </Tooltip>
          );
        } else if (row.status === 'validated') {
          return (
            <Tooltip
              title='Applicant has passed data validation, sent to Credit Review'
              arrow
              placement='right'
            >
              <Chip
                sx={{ minWidth: 17, width: 170, maxWidth: 170 }}
                color='success'
                label={
                  row.status?.charAt(0).toUpperCase() + row.status?.slice(1)
                }
              />
            </Tooltip>
          );
        } else if (row.status === 'validation_failed') {
          return (
            <Tooltip
              title='Applicant has failed data validation'
              arrow
              placement='right'
            >
              <Chip
                sx={{ minWidth: 17, width: 170, maxWidth: 170 }}
                color='error'
                label={'Validation Failed'}
              />
            </Tooltip>
          );
        }
      },
    },
    {
      flex: 0.1,
      minWidth: 170,
      sortable: true,
      field: 'status',
      headerName: 'Action',
      // accessorKey: 'status',
      // header: ' Action',
      renderCell: ({ row }) => (
        <p
          onClick={() => {
            setId(row?._id);
            getRequestDetails(row?._id);
            dispatch(getPendingNotifications());
          }}
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            color:
              row?.status === 'pending' || row?.status === 'partially_validated'
                ? 'red'
                : 'black',
          }}
        >
          {row?.status === 'pending' || row?.status === 'partially_validated'
            ? 'Validate'
            : 'View Details'}
        </p>
      ),
    },
  ];

  const detailsColumns = [
    {
      accessorKey: 'data_field',
      header: 'Data Field',
      minSize: 80,
      maxSize: 80,
      size: 80,
    },

    {
      accessorKey: 'sme_data',
      minSize: 100,
      maxSize: 100,
      size: 100,
      header: 'SME Submitted Data',
      Cell: ({ cell }) => {
        if (
          cell.row.original.field_name === 'country_of_incorporation' &&
          cell.row.original.sme_data === ''
        ) {
          return 'N.A';
        }
        if (cell.row.original.field_name === 'sfe_certificates') {
          let urls = cell.row.original.sme_data.split(',');
          let newUrls = '';
          urls?.map((e) => {
            newUrls += `<a href=${e} target="_blank" >` + e + `</a> <br/>`;
          });
          return <div dangerouslySetInnerHTML={{ __html: newUrls }} />;
          //return newUrls;
        } else {
          return `${
            typeof cell.row.original.sme_data === 'object'
              ? cell.row.original.sme_data?.name
              : cell.row.original.sme_data
          }`;
        }
      },
      // accessorFn: (row) => {
      //   if (row.field_name === 'sfe_certificates') {
      //     let urls = row.sme_data.split(',');
      //     let newUrls = '';
      //     urls?.map((e) => {
      //       newUrls += '<a herf="_blank">' + e + '</a>';
      //     });
      //     return <div dangerouslySetInnerHTML={{ __html: newUrls }} />;
      //     //return newUrls;
      //   } else {
      //     return `${
      //       typeof row.sme_data === 'object' ? row.sme_data?.name : row.sme_data
      //     }`;
      //   }
      // },
    },

    {
      accessorKey: ' ',
      header: 'Is this information correct? ',
      minSize: 100,
      maxSize: 100,
      size: 100,
      Cell: ({ cell }) => {
        if (
          cell.row.original.field_name !== 'sfe_certificates' &&
          cell.row.original.field_name !== 'coupon_code' &&
          cell.row.original.field_name !== 'current_bank' &&
          cell.row.original.field_name !== 'gender' &&
          cell.row.original.field_name !== 'designation' &&
          cell.row.original.field_name !== 'facility_type' &&
          cell.row.original.field_name !== 'entity_type'

          // ||
          //   cell.row.field_name !== 'current_bank'
        ) {
          return (
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              disabled={
                validationStatus || isDBG || isChannel || isBDF || isBNR
              }
            >
              <Select
                // defaultValue={'yes'}
                className='MuiOutlinedInput-root	'
                variant='standard'
                InputProps={{
                  disableUnderline: false,
                  readOnly:
                    validationStatus || isDBG || isChannel || isBDF || isBNR,
                }}
                defaultValue={cell.row.original.verified_status || 'none'}
                onChange={(e) => {
                  let { value } = e.target;
                  cell.row.original.verified_status = value;
                  update({
                    verified_status: value,
                    field_name: cell.row.original.field_name,
                  });
                }}
              >
                <MenuItem value='none'>Select</MenuItem>
                <MenuItem value='yes'>Yes</MenuItem>
                <MenuItem value='no'>No</MenuItem>
              </Select>
            </FormControl>
          );
        }
      },
    },
  ];
  const detailsColumnsGBBAI = [
    {
      accessorKey: 'data_field',
      header: 'Data Field',
      minSize: 200,
      maxSize: 200,
      size: 200,
    },

    {
      accessorKey: 'sme_data',
      header: 'SME Submitted Data ',
      minSize: 200,
      maxSize: 200,
      size: 200,
      Cell: ({ cell }) => {
        if (
          cell.row.original.field_name === 'country_of_incorporation' &&
          cell.row.original.sme_data === ''
        ) {
          return 'N.A';
        }
        if (cell.row.original.field_name === 'sfe_certificates') {
          let urls = cell.row.original.sme_data.split(',');
          let newUrls = '';
          urls?.map((e) => {
            newUrls += `<a href=${e} target="_blank" >` + e + `</a> <br/>`;
          });
          return <div dangerouslySetInnerHTML={{ __html: newUrls }} />;
          //return newUrls;
        } else {
          return `${
            typeof cell.row.original.sme_data === 'object'
              ? cell.row.original.sme_data?.name
              : cell.row.original.sme_data
          }`;
        }
      },
      // accessorFn: (row) => {
      //   if (row.field_name === 'sfe_certificates') {
      //     let urls = row.sme_data.split(',');
      //     let newUrls = '';
      //     urls?.map((e) => {
      //       newUrls += '<a herf="_blank">' + e + '</a>';
      //     });
      //     return <div dangerouslySetInnerHTML={{ __html: newUrls }} />;
      //     //return newUrls;
      //   } else {
      //     return `${
      //       typeof row.sme_data === 'object' ? row.sme_data?.name : row.sme_data
      //     }`;
      //   }
      // },
    },
    {
      accessorKey: 'primary_verifier',
      header: 'Primary Verifier',
      minSize: 130,
      maxSize: 130,
      size: 130,
    },
    {
      accessorKey: 'gbb_data',
      header: 'CBG Validated Data',
      minSize: 130,
      maxSize: 140,
      size: 140,
    },
    {
      accessorKey: 'secondary_verifier',
      header: 'Secondary Verifier',
      minSize: 130,
      maxSize: 130,
      size: 130,
    },
    {
      accessorKey: 'stac_ai_data',
      header: 'Stac.ai Confidence Level',
      minSize: 130,
      maxSize: 130,
      size: 130,
    },
    {
      accessorKey: ' ',
      header: 'Is this information correct? ',
      minSize: 130,
      maxSize: 130,
      size: 130,
      Cell: ({ cell }) => {
        if (
          cell.row.original.field_name !== 'sfe_certificates' &&
          cell.row.original.field_name !== 'coupon_code' &&
          cell.row.original.field_name !== 'current_bank' &&
          cell.row.original.field_name !== 'gender' &&
          cell.row.original.field_name !== 'designation' &&
          cell.row.original.field_name !== 'facility_type' &&
          cell.row.original.field_name !== 'entity_type'
          // ||
          //   cell.row.field_name !== 'current_bank'
        ) {
          return (
            <FormControl
              sx={{ m: 1, minWidth: 120, maxWidth: 120 }}
              disabled={
                validationStatus || isDBG || isChannel || isBDF || isBNR
              }
            >
              <Select
                // defaultValue={'yes'}
                className='MuiOutlinedInput-root	'
                variant='standard'
                InputProps={{
                  disableUnderline: false,
                  readOnly:
                    validationStatus || isDBG || isChannel || isBDF || isBNR,
                }}
                defaultValue={cell.row.original.verified_status || 'none'}
                onChange={(e) => {
                  let { value } = e.target;
                  cell.row.original.verified_status = value;
                  update({
                    verified_status: value,
                    field_name: cell.row.original.field_name,
                  });
                }}
              >
                <MenuItem value='none'>Select</MenuItem>
                <MenuItem value='yes'>Yes</MenuItem>
                <MenuItem value='no'>No</MenuItem>
              </Select>
            </FormControl>
          );
        }
      },
    },
  ];

  const getValidationRequests = async (status) => {
    setValidationData([]);
    try {
      setIsLoading(true);
      const params = {
        page: paginationModel?.page + 1,
        size: paginationModel?.pageSize,
        status: status === 'all' ? '' : status,
        search: searchKeyword,
        sort_field: sortField,
        sort_order: sortOrder,
        source: source,
      };
      const result = await getValidationData(params);
      if (result) {
        setValidationData(result?.data?.data?.data);
        setPageCount(parseInt(result?.data?.data?.total));
      }
    } catch (error) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error,
        })
      );
    } finally {
      // setIsClear(false);
      // setSearchKeyword('');
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getValidationRequests(statusId);
  }, [source]);
  const handleSort = async (newSort) => {
    // console.log(newSort);
    if (newSort.length) {
      setSortField(newSort[0].field);
      setSortOrder(newSort[0].sort);
    } else {
      setSortField('');
      setSortOrder('');
    }
  };

  // const pagination = useMemo(
  //   () => ({
  //     pageIndex,
  //     pageSize,
  //   }),
  //   [pageIndex, pageSize]
  // );

  const ScrollToTop = () => {
    const element = document.getElementById('top');

    element.scrollIntoView(
      {
        behavior: 'smooth',
      },
      1000
    );
  };
  useEffect(() => {
    getValidationRequests(statusId);

    // eslint-disable-next-line
  }, [paginationModel, sortOrder, sortField, isClear]);

  // useEffect(() => {
  //   // if (searchKeyword.length >= 3) {
  //   getValidationRequests(statusId);
  //   // }
  // }, [isClear]);
  useEffect(() => {
    ScrollToTop();
  }, [validationData]);
  const handleVerify = async () => {
    setIsLoad(true);
    setIsLoadingSumbit(true);
    let sample = {};
    // eslint-disable-next-line
    sample['request_id'] = id;
    sample['type'] = 'credit_review';
    // eslint-disable-next-line
    validationDetails.map((i) => {
      if (
        i.field_name === 'sfe_certificates' ||
        i.field_name === 'coupon_code' ||
        i.field_name === 'current_bank' ||
        i.field_name === 'gender' ||
        i.field_name === 'designation' ||
        i.field_name === 'facility_type' ||
        i.field_name === 'entity_type'
      ) {
        return;
      } else if (
        i.verified_status !== '' ||
        i.verified_status !== 'none' ||
        i.verified_status !== null ||
        i.verified_status !== undefined
      ) {
        sample[i.field_name] = i.verified_status;
      } else {
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: 'Please review all the information.', //result?.message,
          })
        );
      }
    });

    try {
      const result = await updateValidationDetails(sample);

      if (result) {
        setId('');
        handleClose();
        dispatch(getPendingNotifications());
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'success',
            message: 'Data validation request updated successfully', //result?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error,
        })
      );
    } finally {
      getValidationRequests(statusId);
      dispatch(getPendingNotifications());

      setIsLoad(false);
      setIsLoadingSumbit(false);
    }
  };
  const handleOnHold = async () => {
    setIsHold(true);
    setIsLoadingSumbit(true);
    let sample = {};
    // eslint-disable-next-line
    sample['request_id'] = id;
    sample['type'] = 'on_hold';
    // eslint-disable-next-line
    validationDetails.map((i) => {
      if (
        i.field_name === 'sfe_certificates' ||
        i.field_name === 'coupon_code' ||
        i.field_name === 'current_bank' ||
        i.field_name === 'gender' ||
        i.field_name === 'designation' ||
        i.field_name === 'facility_type' ||
        i.field_name === 'entity_type'
      ) {
        return;
      } else if (
        i.verified_status !== '' ||
        i.verified_status !== 'none' ||
        i.verified_status !== null ||
        i.verified_status !== undefined
      ) {
        sample[i.field_name] = i.verified_status;
      } else {
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: 'Please review all the information.', //result?.message,
          })
        );
      }
    });

    try {
      const result = await updateValidationDetails(sample);

      if (result) {
        setId('');
        handleClose();
        dispatch(getPendingNotifications());
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'success',
            message: 'Data validation request updated successfully', //result?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error,
        })
      );
    } finally {
      getValidationRequests(statusId);
      dispatch(getPendingNotifications());

      setIsHold(false);
      setIsLoadingSumbit(false);
    }
  };
  const handleDraft = async () => {
    setIsLoad(true);
    setIsDraftSumbit(true);
    let sample = {};
    // eslint-disable-next-line
    sample['request_id'] = id;
    sample['type'] = 'draft';

    // eslint-disable-next-line
    validationDetails.map((i) => {
      sample[i.field_name] = i.verified_status;
    });

    try {
      const result = await updateValidationDetails(sample);

      if (result) {
        setId('');
        handleClose();
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'success',
            message: 'Data validation request updated successfully', //result?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error,
        })
      );
    } finally {
      getValidationRequests(statusId);
      dispatch(getPendingNotifications());

      setIsLoad(false);
      setIsDraftSumbit(false);
    }
  };

  const handleClose = (e, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setIsOpen(false);
    setIsConfirm(false);
    setDataRetrive(false);
    // setIsGBBLoading(false);
  };

  const handlePopup = async () => {
    var flag = false;
    var required = true;
    // eslint-disable-next-line
    validationDetails.map((i) => {
      if (
        i.field_name === 'sfe_certificates' ||
        i.field_name === 'coupon_code' ||
        i.field_name === 'current_bank' ||
        i.field_name === 'gender' ||
        i.field_name === 'designation' ||
        i.field_name === 'facility_type' ||
        i.field_name === 'entity_type'
      ) {
        return;
      } else if (
        i.verified_status === '' ||
        i.verified_status === 'none' ||
        i.verified_status === null ||
        i.verified_status === undefined
      ) {
        required = false;
      }
      if (i.verified_status === 'no') {
        flag = true;
      }
    });
    if (!required) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: 'Please review all the information.', //result?.message,
        })
      );
    } else {
      if (flag) {
        setIsOpen(true);
      } else {
        setIsConfirm(true);
        // handleVerify();
      }
    }
  };

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageCount !== undefined ? pageCount : prevRowCountState
    );
  }, [pageCount, setRowCountState]);
  return (
    <Grid container className={classes.root} id='top'>
      <SnackBar />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Typography
            variant='h5'
            component='h3'
            sx={{ fontWeight: 700, px: 8, py: 4 }}
          >
            {id === '' ? t('Data_Validation') : t('Validate_Information')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          {id !== '' && validationDetails && (
            <>
              <Button
                variant='outlined'
                sx={{ mr: 8 }}
                onClick={() => {
                  dispatch(getPendingNotifications());
                  setId('');
                }}
              >
                {t('Back')}
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      {/* <Grid item xs={12} md={12} lg={12} marginTop='10px' marginLeft={'10px'}>
        <Typography
          variant='h5'
          component='h3'
          sx={{ fontWeight: 700, px: 8, py: 4 }}
        >
          {t('Data_Validation')}
        </Typography> */}
      {/* </Grid> */}
      {id === '' && (
        <Grid container px={8} py={4} spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label='Search'
              placeholder='Search here'
              fullWidth
              id='outlined-start-adornment'
              // sx={{ m: 1, width: '25ch' }}
              value={searchKeyword}
              // sx={{ minWidth: isMobile ? 200 : 440, maxWidth: '100%' }}
              onKeyDown={(ev) => {
                // console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                  // Do code here
                  ev.preventDefault();
                  // if (searchKeyword.length >= 3) {
                  getValidationRequests(statusId);
                  // }
                }
              }}
              onChange={(e) => setSearchKeyword(e.target.value)}
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <ICONS.SEARCH />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    {searchKeyword !== '' && (
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => {
                          setSearchKeyword('');
                          setTimeout(() => {
                            setIsClear(!isClear);
                            // getValidationRequests(statusId);
                          }, 500);
                        }}
                        edge='end'
                      >
                        <ICONS.HIGHLIGHTSOFF
                          sx={{
                            color: 'black',
                            fontSize: '16px',
                          }}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl
              // sx={{ minWidth: isMobile ? 235 : 410, maxWidth: '100%' }}
              fullWidth
              size='small'
            >
              <InputLabel id='statusId'>Status</InputLabel>
              <Select
                labelId='statusId'
                id='statusId-select'
                value={statusId}
                label='Status'
                fullWidth
                onChange={handleChange}
              >
                {options.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl
              // sx={{ minWidth: isMobile ? 235 : 410, maxWidth: '100%' }}
              fullWidth
              size='small'
            >
              <InputLabel id='tof'>Type of Financing</InputLabel>
              <Select
                labelId='tof'
                id='tof-select'
                value={source}
                label='Type of Financing'
                fullWidth
                onChange={handleToFChange}
              >
                {sourceList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={12} sx={{ px: 8, py: 4 }}>
        {id === '' && (
          <DataGrid
            sx={{
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-columnHeaders ': {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: '#fff',
                '& .MuiButtonBase-root.MuiIconButton-root ': {
                  color: '#fff',
                },
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
              },
              '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor': {
                // borderColor: `${theme.palette.primary.main}`,
              },

              '& .MuiDataGrid-columnSeparator ': {
                color: '#fff',
              },
              '& .MuiDataGrid-virtualScroller': {
                // border: `1px solid ${theme.palette.primary.main}`,
                border: `.25px solid grey`,
              },
            }}
            autoHeight
            rowHeight={62}
            rows={validationData}
            loading={isLoading}
            // onRowClick={(row) => {
            //   // // console.log(row);
            //   if (row?.row?.total_applications > 0) {
            //     handleApplications(row?.row);
            //   }
            // }}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            onSortModelChange={(newSortModel) => handleSort(newSortModel)}
            getRowId={(row) => row._id}
            columns={requestsColumns}
            enableHiding={false}
            enableColumnActions={false}
            enableColumnFilterModes={false}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableGlobalFilter={false}
            enableTopToolbar={false}
            disableColumnMenu={true}
            disableRowSelectionOnClick
            rowCount={rowCountState}
            paginationMode='server'
            pageSizeOptions={[5, 10, 25, 50, 100]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            columnBuffer={5}
          />
          // <MaterialReactTable
          //   columns={requestsColumns}
          //   data={validationData}
          //   enableHiding={false}
          //   enableColumnActions={false}
          //   enableColumnFilters={false}
          //   enableDensityToggle={false}
          //   enableFullScreenToggle={false}
          //   enableGlobalFilter={false}
          //   enableTopToolbar={false}
          //   manualPagination={true}
          //   // manualSorting={true}
          //   rowCount={pageCount}
          //   onSortingChange={(old, new1) => // console.log(old, new1)}
          //   state={{ pagination, isLoading }}
          //   onPaginationChange={setPagination}
          // />
        )}
        {id !== '' && validationDetails && (
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              mb: 2,
            }}
          >
            <Grid container spacing={4}>
              {/* <Box display='flex' justifyContent='flex-start' mb={2}> */}
              {id !== '' && loanAmount && (
                <Grid
                  item
                  xs={12}
                  md={9}
                  lg={9}
                  sx={{
                    display: 'flex',

                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        // display: 'flex',
                        // alignItems: 'center',
                        // alignContent: 'center',
                        // mr: 2,
                      }}
                    >
                      Loan Amount (GHS) :{' '}
                    </Typography>{' '}
                    {parseFloat(loanAmount)
                      .toFixed(2)

                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </Typography>
                </Grid>
              )}

              {id !== '' && showGBBAI && (
                <Grid item xs={12} md={3} lg={3} sx={{}}>
                  <Box
                    sx={{
                      display: 'flex',
                      mb: 2,

                      justifyContent: {
                        lg: 'flex-end',
                        md: 'flex-end',
                        sm: 'flex-start',
                        xs: 'flex-start',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 700,
                        display: 'flex',
                        // mr: 2,
                        // justifyContent: 'flex-end',
                      }}
                    >
                      {/* <Tooltip
                        title='Retrieve customer data from Stac.ai and Ghana Bank Bridge (GBB)'
                        arrow
                        placement='left-start'
                      > */}
                      {!isDBG && !isChannel && !isBDF && !isBNR && (
                        <Button
                          variant='contained'
                          loading={isGBBLoading}
                          title='Retrieve customer data from Stac.ai and Ghana Bank Bridge (GBB)'
                          disabled={isGBBAI}
                          sx={{ textTransform: 'none' }}
                          onClick={() => handleGGBAI()}
                        >
                          Retrieve Third-Party Data
                        </Button>
                      )}
                      {/* <Typography sx={{ display: 'flex' }}>
                          <Typography
                            sx={{
                              fontWeight: 700,
                              display: 'flex',
                              // flexDirection: 'row',
                              // mr: 2,
                            }}
                          >
                            {'  '}
                            Loan Tenure :{' '}
                          </Typography>
                          {'  '} {loanTenure}
                        </Typography> */}
                      {/* </Tooltip> */}
                    </Box>
                  </Box>
                  <Grid item xs={12} md={12} lg={12} sx={{}}></Grid>
                </Grid>
              )}
              {/* </Box> */}
            </Grid>
          </Grid>
        )}
        {id !== '' && (
          <Grid
            container
            spacing={4}
            sx={{
              mb: 4,
            }}
          >
            {facilityType && (
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                sx={{
                  display: 'flex',

                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <Typography sx={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      display: 'flex',
                      // mr: 2,
                    }}
                  >
                    {'  '}
                    Facility type :{' '}
                  </Typography>
                  {'  '} {facilityType}
                </Typography>
              </Grid>
            )}
            {loanTenure && (
              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                sx={{
                  display: 'flex',

                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <Typography sx={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      display: 'flex',
                      // flexDirection: 'row',
                      // mr: 2,
                    }}
                  >
                    {'  '}
                    Loan Tenure :{' '}
                  </Typography>
                  {'  '} {loanTenure}
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={5}
              lg={5}
              // display={'flex'}
              // justifyContent={'flex-end'}
            >
              {showGBBAI && isGBBAI && (
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: {
                      lg: 'flex-end',
                      md: 'flex-end',
                      sm: 'flex-start',
                      xs: 'flex-start',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      display: 'flex',
                      justifyContent: {
                        lg: 'flex-end',
                        md: 'flex-end',
                        sm: 'flex-start',
                        xs: 'flex-start',
                      },
                      // flexDirection: 'row',
                      // mr: 2,
                    }}
                  >
                    {'  '}
                    Last Retrieved date :{' '}
                  </Typography>
                  {'  '} {formatDate(isGBBAI)}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
        {id !== '' && validationDetails && (showGBBAI || showRefresh) && (
          <Grid container>
            <Grid item xs={6}>
              {showRefresh && (
                <Typography
                  sx={{
                    // fontWeight: 700,
                    display: 'flex',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    justifyContent: {
                      lg: 'flex-start',
                      md: 'flex-start',
                      sm: 'flex-start',
                      xs: 'flex-start',
                    },
                    // flexDirection: 'row',
                    // mr: 2,
                  }}
                  onClick={() => getRequestDetails(id)}
                >
                  {'  '}
                  Click to refresh for Secondary Verifier data
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
        {id !== '' && validationDetails && (
          <>
            <MaterialReactTable
              columns={isGBBAI ? detailsColumnsGBBAI : detailsColumns}
              data={validationDetails}
              layoutMode='grid'
              enableHiding={false}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableGlobalFilter={false}
              enableTopToolbar={false}
              enablePagination={false}
              // manualPagination={true}
              // rowCount={pageCount}
              state={{ isLoading }}

              // onPaginationChange={setPagination}
            />
            {!validationStatus && !isDBG && !isChannel && !isBDF && !isBNR && (
              // <Box
              //   sx={{
              //     display: 'flex',
              //     justifyContent: 'flex-end',
              //     mt: 4,
              //   }}
              // >
              <Grid container spacing={4} mt={3}>
                <Grid item xs={6} md={6} lg={6} xl={6}></Grid>
                <Grid item xs={12} md={12} lg={3} xl={3}>
                  <LoadingButton
                    loading={isDraftSumbit}
                    disabled={isDraftSumbit}
                    fullWidth
                    sx={{
                      // my: 2,
                      // minWidth: 200,
                      // width: {
                      //   xs: 1,
                      //   md: 1,
                      //   lg: 0.1,
                      //   xl: 0.1,
                      // },

                      textTransform: 'none',
                      '& .MuiCircularProgress-root ': {
                        color: '#17236E',
                      },
                    }}
                    variant='outlined'
                    onClick={() => handleDraft()}
                  >
                    {t('Save_as_Draft')}
                  </LoadingButton>
                </Grid>
                <Grid item xs={12} md={12} lg={3} xl={3}>
                  <LoadingButton
                    loading={isLoadingSumbit}
                    disabled={isLoadingSumbit}
                    fullWidth
                    sx={{
                      // ml: 2,
                      // minWidth: 200,
                      // width: {
                      //   xs: 1,
                      //   md: 1,
                      //   lg: 0.1,
                      //   xl: 0.1,
                      // },
                      textTransform: 'none',
                      '& .MuiCircularProgress-root ': {
                        color: '#ffff',
                      },
                    }}
                    variant='contained'
                    onClick={() => handlePopup()}
                  >
                    {t('Submit_Credit_review')}
                  </LoadingButton>
                </Grid>
              </Grid>
              // </Box>
            )}
          </>
        )}
      </Grid>
      <Disclaimer
        isOpen={isOpen}
        isLoad={isLoad}
        isHold={isHold}
        handleClose={handleClose}
        handleVerify={handleVerify}
        handleOnHold={handleOnHold}
      />
      <Confirm
        isOpen={isConfirm}
        isLoad={isLoad}
        handleClose={handleClose}
        handleVerify={handleVerify}
      />
      <DataRetrive
        isOpen={dataRetrive}
        isLoad={isLoad}
        handleClose={handleClose}
        handleRefresh={() => getRequestDetails(id)}
        // handleVerify={handleVerify}
      />
    </Grid>
  );
}

export default DataValidation;
